import moment from "moment";

export const toRupiah = (value) => {
    var rupiah = "";
    var valuerev = value.toString().split("").reverse().join("");
    for (var i = 0; i < valuerev.length; i++)
        if (i % 3 == 0) rupiah += valuerev.substr(i, 3) + ".";
    return (
        "Rp. " +
        rupiah
            .split("", rupiah.length - 1)
            .reverse()
            .join("")
    );
};

export const calculateTimeDifference = (date, time) => {
    const currentDateTime = moment();
    const selectedDateTime = moment(`${date}, ${time}`);
    return selectedDateTime.diff(currentDateTime.startOf('day'), 'minutes');
};

export const getLengthPassAdult = (data) => {
  return data?.filter((item) => item?.title === "Mrs." || item?.title === "Mr." || item?.title === "Ms.")?.length
};

export const getLengthPassChild = (data) => {
  return data?.filter((item) => item?.title === "Child")?.length
};

export const getLengthPassInfant = (data) => {
  return data?.filter((item) => item?.title === "Infant")?.length
};

export const moreThanInfantAge = (birthDate) => {
  const currentDate = moment();
  const twoYearsOfBirth = moment(birthDate).add(2, "years").format("YYYY-MM-DD");
  const diffBirthDateFromNow = moment(birthDate).diff(currentDate, 'days');
  const diffTwoYearsFromBirth = moment(birthDate).diff(twoYearsOfBirth, 'days');

  return diffBirthDateFromNow < diffTwoYearsFromBirth
};

export const getContactInfoBooking = (dataBooking) => {
  if (!dataBooking) return;

  const bookerEmail = dataBooking?.bookers_email || dataBooking?.booker_data?.bookers_email;
  const bookerPhoneNumber = dataBooking?.bookers_phone || dataBooking?.booker_data?.bookers_phone;
  const bookerCountryCode = dataBooking?.bookers_country_code || dataBooking?.booker_data?.bookers_country_code;
  const passengerEmail = dataBooking?.list_order_passengers[0]?.email;
  const passengerPhoneNumber = dataBooking?.list_order_passengers[0]?.phone_number;
  const passengerCountryCode = dataBooking?.list_order_passengers[0]?.country_code;
  const customerEmail = dataBooking?.customer_email || dataBooking?.customer_data?.customer_email;
  const customerHandphone = dataBooking?.customer_handphone || dataBooking?.customer_data?.customer_handphone;
  const customerCountryCode = dataBooking?.customer_country_code || dataBooking?.customer_data?.customer_country_code;

  let contactInfo;

  switch (true) {
    case !passengerEmail || !passengerPhoneNumber:
      contactInfo = `${bookerEmail} / ${
        bookerCountryCode
          ? `${bookerCountryCode}${bookerPhoneNumber}`
          : bookerPhoneNumber
      }`;
      break;
    case !customerHandphone && !customerEmail:
      contactInfo = `${passengerEmail} / ${
        passengerCountryCode
          ? `${passengerCountryCode}${passengerPhoneNumber}`
          : passengerPhoneNumber
      }`;
      break;
    case customerHandphone && customerEmail:
      contactInfo = `${customerEmail} / ${
        customerCountryCode
          ? `${customerCountryCode}${customerHandphone}`
          : customerHandphone
      }`;
      break;
    case !customerEmail && customerHandphone:
      contactInfo = `${
        customerCountryCode
          ? `${customerCountryCode}${customerHandphone}`
          : customerHandphone
      }`;
      break;
    default:
      contactInfo = bookerEmail;
  }

  return contactInfo
}

export const formatRoleName = (str) => {
  return str.replace(/_/g, " ").replace(/\b\w/g, (char) => char.toUpperCase());
}

export const getErrorMessage = (lang, errorResponse) => {
  const fallbackMessage = errorResponse?.message;
  
  const errorMessagesByLang = {
    id: errorResponse?.message,
    en: errorResponse?.message_eng,
  };
  
  return errorMessagesByLang[lang] || fallbackMessage;
}

export const conversionToBytes = (SIZE_MB) => {
  const BYTES_IN_MB = 1024 * 1024;
  return SIZE_MB * BYTES_IN_MB;
}

export const formatPrice = (value) => {
  if (!value) return 0;
  return Number(String(value).replace(".", ""));
};
  
  
  
