import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Col,
  Row
} from "reactstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { Divider } from "@mui/material";

import Input from "../../../components/forms/Input";
import ButtonModal from "../../../components/modal/ButtonModal";
import DynamicForm from "../../../components/forms/DynamicForm";
import Select2Multi from "../../../components/forms/Select2Multi";
import { withTrans } from "../../../i18n/withTrans";
import InputPrepend from "../../../components/forms/InputPrepend";
import Checkbox from "../../../components/forms/Checkbox";
import { exceptionSymbolNumbering, FIRST_LAST_SPACE_REGEX, flightTypeList, routeTypeList } from "../../../utils/Constants";
import { toastError } from "../../../components/commons/toast";
import { formatPrice } from "../../../utils/Helper";

const MasterDataModal = ({
  t,
  show,
  toggle,
  action,
  data,
  confirm,
  airport_list,
  data_post,
  data_put,
  error_message
}) => {
  const formikRef = useRef();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [step, setStep] = useState(1);

  const title =
    (data ? t("commons.edit") : t("commons.add")) + " " + t("field.lounge");

  const stepsForm = [
    t("field.information"),
    t("field.operational"),
  ];

  const handleNextStep = async (nextStep, formik) => {
    if (nextStep > step) {

      await formik.validateForm(validation[step]);

      Object.keys(formik.values).forEach((field) => {
        formik.setFieldTouched(field, true);
      });

      const stepFields = Object.keys(validation[step].fields);
      const stepErrors = Object.keys(formik.errors).filter((key) => stepFields.includes(key));

      if (stepErrors.length === 0) {
        setStep(nextStep);
      }
    } else {
      setStep(nextStep);
    }
  };

  const initial = data
    ? {
        id: data.id,
        name: data.name,
        airport_ids: data.airport_list?.map((item) => item?.id),
        route_type: data.route_type === "all"
          ? routeTypeList(t)?.map((item) => item?.value)
          : [data?.route_type],
        flight_type: data.flight_type === parseInt(flightTypeList(t)?.map((item) => item?.value).join(""))
          ? flightTypeList(t)?.map((item) => item?.value)
          : [data?.flight_type],
        price_adult: data.price_adult,
        price_child: data.price_child,
        price_infant: data.price_infant,
        basic_price_adult: data.basic_price_adult,
        basic_price_child: data.basic_price_child,
        basic_price_infant: data.basic_price_infant,
        is_include_ppn: data.is_include_ppn ? ["1"] : null,
        ppn_percentage: data.ppn_percentage,
        operational_list: data?.operational_list?.length ? data?.operational_list : [
          {days: 'Sunday'}, {days: 'Monday'}, {days: 'Tuesday'}, {days: 'Wednesday'}, {days: 'Thursday'}, {days: 'Friday'}, {days: 'Saturday'}
        ]
      }
    : {
        name: "",
        airport_ids: null,
        route_type: "",
        flight_type: "",
        price_adult: null,
        price_child: null,
        price_infant: null,
        basic_price_adult: null,
        basic_price_child: null,
        basic_price_infant: null,
        is_include_ppn: null,
        ppn_percentage: null,
        operational_list: [
          {days: 'Sunday'}, {days: 'Monday'}, {days: 'Tuesday'}, {days: 'Wednesday'}, {days: 'Thursday'}, {days: 'Friday'}, {days: 'Saturday'}
        ]
      };

  const validation = {
    1 : Yup.object().shape({
      name: Yup.string().required(`${t("field.lounge")} ${t("commons.required")}`).nullable(),
      airport_ids: Yup.array().required(`${t("field.airport")} ${t("commons.required")}`).nullable(),
      route_type: Yup.array().required(`${t("field.routeType")} ${t("commons.required")}`).nullable(),
      flight_type: Yup.array().required(`${t("field.flightType")} ${t("commons.required")}`).nullable(),
      price_adult: Yup.number()
        .min(1, `${t("field.price")} ${t("commons.required")}`)
        .required(`${t("field.price")} ${t("commons.required")}`)
        .nullable(),
      price_child: Yup.number()
        .min(1, `${t("field.price")} ${t("commons.required")}`)
        .required(`${t("field.price")} ${t("commons.required")}`)
        .nullable(),
      basic_price_adult: Yup.number()
        .min(1, `${t("field.price")} ${t("commons.required")}`)
        .required(`${t("field.price")} ${t("commons.required")}`)
        .nullable(),
      basic_price_child: Yup.number()
        .min(1, `${t("field.price")} ${t("commons.required")}`)
        .required(`${t("field.price")} ${t("commons.required")}`)
        .nullable(),
    }),
    2 : Yup.object().shape({
      operational_list: Yup.array().of(
        Yup.object().shape({
          open_time: Yup.string()
            .nullable()
            .test("open_time-required", `${t("commons.required")}`, function (value) {
              const { operational, closed_time } = this.parent;
              if (!operational && (!value || !closed_time)) {
                return false;
              }
              return true;
            }),
          closed_time: Yup.string()
            .nullable()
            .test("closed_time-required", `${t("commons.required")}`, function (value) {
              const { operational, open_time } = this.parent;
              if (!operational && (!value || !open_time)) {
                return false;
              }
              return true;
            })
            .test("closed_time-after-open_time", `invalid`, function (value) {
              const { open_time } = this.parent;
              if (open_time && value && open_time >= value) {
                return false;
              }
              return true;
            }),
          operational: Yup.string()
            .nullable()
            .test("operational-required", `${t("commons.required")}`, function (value) {
              const { open_time, closed_time } = this.parent;
              if (!value && !open_time && !closed_time) {
                return false;
              }
              return true;
            }),
        })
      ),
    })
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (!error_message) return;

    setLoadingSubmit(false);
  }, [error_message]);

  useEffect(() => {
    if (data_post?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_post]);

  useEffect(() => {
    if (data_put?.data?.status === 200) {
      setLoadingSubmit(false);
    }
  }, [data_put]);

  const priceNotValid = (values) => {
    if (
      formatPrice(values.basic_price_adult) > formatPrice(values.price_adult) ||
      formatPrice(values.basic_price_child) > formatPrice(values.price_child) ||
      formatPrice(values.basic_price_infant) > formatPrice(values.price_infant)
    ) {
      toastError(t("masterData.loungePriceHandle"))
      return true
    }
    return false
  }

  const handleCheckOperational = (isChecked, value, formik, index) => {
    const operational = isChecked ? value : null;

    formik.setFieldValue(`operational_list[${index}].operational`, operational);

    if (isChecked && operational === 'fullday') {
      formik.setFieldValue(`operational_list[${index}].open_time`, "00:00");
      formik.setFieldValue(`operational_list[${index}].closed_time`, "23:59");
    }
    
    if (isChecked && operational === 'closed') {
      formik.setFieldValue(`operational_list[${index}].open_time`, null);
      formik.setFieldValue(`operational_list[${index}].closed_time`, null);
      formik.setFieldTouched(`operational_list[${index}].open_time`, false);
      formik.setFieldTouched(`operational_list[${index}].closed_time`, false);
    }

    setTimeout(() => {
      formik.validateForm();
    }, 0);
  }

  return (
    <>
      <Modal
        isOpen={show}
        modalTransition={{ timeout: 700 }}
        backdropTransition={{ timeout: 1300 }}
        className="modal-medium-width"
      >
        <ModalHeader className="text-extra-bold pt-5">
          {" "}
          {title}{" "}
          <span className="close clickable"
            onClick={() => {
              setStep(1);
              toggle();
            }}
          >
            &times;
          </span>
        </ModalHeader>
        <Formik
          innerRef={formikRef}
          initialValues={initial}
          validationSchema={validation[step]}
          onSubmit={async (values, { setStatus }) => {
            setStatus();
            const route_type = values?.route_type?.length === routeTypeList(t)?.length
              ? "all"
              : values?.route_type?.find(() => true);

            const flight_type = values?.flight_type?.length === flightTypeList(t)?.length
              ? parseInt(values?.flight_type?.sort((a, b) => a - b)?.join(""))
              : values?.flight_type?.find(() => true);

            const param = {
              ...values,
              name: values.name.replace(FIRST_LAST_SPACE_REGEX, ""),
              price_adult: formatPrice(values.price_adult),
              price_child: formatPrice(values.price_child),
              price_infant: values.price_infant == "0" ? 0 : formatPrice(values.price_infant),
              basic_price_adult: formatPrice(values.basic_price_adult),
              basic_price_child: formatPrice(values.basic_price_child),
              basic_price_infant: values.basic_price_infant == "0" ? 0 : formatPrice(values.basic_price_infant),
              ppn_percentage: values.ppn_percentage || 0,
              is_include_ppn: values.is_include_ppn == 1 ? true : false,
              route_type: route_type,
              flight_type: flight_type,
            };

            if (param?.operational_list?.length) {
              param.operational_list = param?.operational_list?.map((item) => {
                return {
                  ...item,
                  operational:
                    item?.open_time &&
                    item?.closed_time &&
                    !(item?.open_time === "00:00" &&
                    item?.closed_time === "23:59")
                      ? "halfday"
                      : item?.operational,
                };
              })
            }
            
            if (!priceNotValid(values)) {
              setLoadingSubmit(true);
              action(param);
              await delay(3000);
              setStep(1);
            }
          }}
        >
          {(props) => (
            <Form>
              <ModalBody className="pb-0">
                <Container>
                  <Stepper nonLinear activeStep={step - 1}>
                    {stepsForm.map((label, index) => (
                      <Step key={label}>
                        <StepButton
                          color="inherit"
                          onClick={() => handleNextStep(index + 1, props)}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                  {step === 1 && (
                    <div className="mt-4 mb-3">
                      <Input
                        name="name"
                        errors={props.errors}
                        touched={props.touched}
                        {...props.getFieldProps("name")}
                        title={t("field.lounge")}
                        type="text"
                      />
                      <Select2Multi
                        title={t("field.airport")}
                        name="airport_ids"
                        options={airport_list}
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.airport_ids}
                        onChange={(name, value) => {
                          let data = value;
                          if (!data.length > 0) {
                            data = "";
                          }
                          props?.setFieldValue("airport_ids", data);
                        }}
                        onBlur={props?.setFieldTouched}
                      />
                      <Select2Multi
                        title={t("field.routeType")}
                        name="route_type"
                        options={routeTypeList(t)}
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.route_type}
                        onChange={(name, value) => {
                          let data = value;
                          if (!data.length > 0) {
                            data = "";
                          }
                          props?.setFieldValue("route_type", data);
                        }}
                        onBlur={props?.setFieldTouched}
                      />
                      <Select2Multi
                        title={t("field.flightType")}
                        name="flight_type"
                        options={flightTypeList(t)}
                        errors={props?.errors}
                        touched={props?.touched}
                        value={props?.values?.flight_type}
                        onChange={(name, value) => {
                          let data = value;
                          if (!data.length > 0) {
                            data = "";
                          }
                          props?.setFieldValue("flight_type", data);
                        }}
                        onBlur={props?.setFieldTouched}
                      />
                      <div className="form-group mt-3 mb-0">
                        <label className="form-label mb-1">
                          Publish Rate
                        </label>
                      </div>
                      <Row>
                        <Col md={4} className="pr-0">
                          <div className="form-group mb-0">
                            <label>
                              {`${t("field.price")} ${t("commons.adult")}`}
                            </label>
                          </div>
                        </Col>
                        <Col md={4} className="pl-0">
                          <div className="form-group mb-0">
                            <label>
                              {`${t("field.price")} ${t("commons.child")}`}
                            </label>
                          </div>
                        </Col>
                        <Col md={4} className="pl-0">
                          <div className="form-group mb-0">
                            <label>
                              {`${t("field.price")} ${t("commons.infantLabel")}`}
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <DynamicForm
                        notAlignedCenter
                        withoutDelete
                        pbRow="0"
                        input={`price_adult`}
                        nameCenter={`price_child`}
                        name={`price_infant`}
                        placeholderLeft={`${t("field.price")} ${t("commons.adult")}`}
                        placeholderCenter={`${t("field.price")} ${t("commons.child")}`}
                        placeholder={`${t("field.price")} ${t("commons.infantLabel")}`}
                        typeLeft="number"
                        type="number"
                        typeCenter="number"
                        currencyLeft
                        currencyCenter
                        currency
                        formik={props}
                        size={{ title: 4, center: 4, right: 4, }}
                      />
                      <div className="form-group mb-0">
                        <label className="form-label mb-1">
                          Basic Rate
                        </label>
                      </div>
                      <Row>
                        <Col md={4} className="pr-0">
                          <div className="form-group mb-0">
                            <label>
                              {`${t("field.price")} ${t("commons.adult")}`}
                            </label>
                          </div>
                        </Col>
                        <Col md={4} className="pl-0">
                          <div className="form-group mb-0">
                            <label>
                              {`${t("field.price")} ${t("commons.child")}`}
                            </label>
                          </div>
                        </Col>
                        <Col md={4} className="pl-0">
                          <div className="form-group mb-0">
                            <label>
                              {`${t("field.price")} ${t("commons.infantLabel")}`}
                            </label>
                          </div>
                        </Col>
                      </Row>
                      <DynamicForm
                        notAlignedCenter
                        withoutDelete
                        input={`basic_price_adult`}
                        nameCenter={`basic_price_child`}
                        name={`basic_price_infant`}
                        placeholderLeft={`${t("field.price")} ${t("commons.adult")}`}
                        placeholderCenter={`${t("field.price")} ${t("commons.child")}`}
                        placeholder={`${t("field.price")} ${t("commons.infantLabel")}`}
                        typeLeft="number"
                        type="number"
                        typeCenter="number"
                        currencyLeft
                        currencyCenter
                        currency
                        formik={props}
                        size={{ title: 4, center: 4, right: 4, }}
                      />
                      <Checkbox
                        customClass="mt-2"
                        name="is_include_ppn"
                        checked={props?.values?.is_include_ppn}
                        onChange={props?.handleChange}
                        value={1}
                        label={t("product.includes_ppn")}
                      />
                      <InputPrepend
                        mt="2"
                        name="ppn_percentage"
                        title="PPN %"
                        type="number"
                        min={0}
                        value={props?.values?.ppn_percentage}
                        onKeyDown={(e) =>
                          exceptionSymbolNumbering.includes(e.key) && e.preventDefault()
                        }
                        onChange={(data) => {
                          const val = data.target.value;
                          props?.setFieldValue(
                            "ppn_percentage",
                            val
                          );
                        }}
                      />
                    </div>
                  )}
                  {step === 2 && (
                    <div className="mt-4">
                      <Row className="mx-0 my-3">
                        <Col md={3} className="pr-0">
                          <label className="form-label text-primary mb-0">
                          {t("field.day")?.charAt(0).toUpperCase() + t("field.day")?.slice(1)}
                          </label>
                        </Col>
                        <Col md={3} className="pr-0"></Col>
                        <Col md={3} className="pr-0">
                          <label className="form-label text-primary mb-0">{t("field.open_time")}</label>
                        </Col>
                        <Col md={3} className="pr-0">
                          <label className="form-label text-primary mb-0">{t("field.close_time")}</label>
                        </Col>
                      </Row>
                      <Divider orientation="horizontal" className="my-2" />
                      {props?.values?.operational_list?.map((item, index) => (
                        <>
                        <Row key={item.days} className="align-items-center mx-0">
                          <Col md={3} className="pr-0">
                            <label className="form-label mb-0">{item.days}</label>
                          </Col>
                          <Col md={3} className="pr-0">
                            <Checkbox
                              name={`operational_list[${index}].operational`}
                              checked={props?.values?.operational_list[index]?.operational}
                              onChange={(e) => handleCheckOperational(e.target.checked, "fullday", props, index)}
                              value="fullday"
                              label={t("field.fullday")}
                              inlineContainer
                              labelRegularStyle
                            />
                            <Checkbox
                              name={`operational_list[${index}].operational`}
                              checked={props?.values?.operational_list[index]?.operational}
                              onChange={(e) => handleCheckOperational(e.target.checked, "closed", props, index)}
                              value="closed"
                              label={t("commons.close")}
                              inlineContainer
                              labelRegularStyle
                              errors={props?.errors}
                              touched={props?.touched}
                            />
                          </Col>
                          <Col md={3} className="pr-0">
                            <Input
                              disabled={
                                props?.values?.operational_list[index]?.operational &&
                                props?.values?.operational_list[index]?.operational !== "halfday"
                              }
                              name={`operational_list[${index}].open_time`}
                              errors={props?.errors}
                              touched={props?.touched}
                              value={props?.values?.operational_list[index]?.open_time || ""}
                              onChange={(e) => {
                                props.setFieldValue(`operational_list[${index}].open_time`, e.target.value);
                              }}
                              type="time"
                              customClassInput="padding-5"
                              customClassForm="mb-0"
                            />
                          </Col>
                          <Col md={3} className="pr-0">
                            <Input
                              disabled={
                                props?.values?.operational_list[index]?.operational &&
                                props?.values?.operational_list[index]?.operational !== "halfday"
                              }
                              name={`operational_list[${index}].closed_time`}
                              errors={props?.errors}
                              touched={props?.touched}
                              value={props?.values?.operational_list[index]?.closed_time || ""}
                              onChange={(e) => {
                                props.setFieldValue(`operational_list[${index}].closed_time`, e.target.value);
                              }}
                              type="time"
                              customClassInput="padding-5"
                              customClassForm="mb-0"
                            />
                          </Col>
                        </Row>
                        <Divider orientation="horizontal" className="my-2" />
                        </>
                      ))}
                    </div>
                  )}
                </Container>
              </ModalBody>
              <ModalFooter className="pt-0">
                {step === 1 && (
                  <ButtonModal
                    toggle={toggle}
                    confirm={() => handleNextStep(2, props)}
                    confirmTitle={t("commons.next")}
                    cancelTitle={t("commons.cancel")}
                    typeConfirm="button"
                  />
                 )}
                {step === 2 && (
                  <ButtonModal
                    toggle={() => handleNextStep(1, props)}
                    confirm={confirm}
                    confirmTitle={t("commons.save")}
                    cancelTitle={t("commons.back")}
                    disabledConfirm={loadingSubmit}
                  />
                )}
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ lounge: { data_post, data_put, error_message } }) => {
  return { data_post, data_put, error_message };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTrans(connect(mapStateToProps, mapDispatchToProps)(MasterDataModal));
