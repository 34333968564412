import React, { Component } from "react";
import get from "lodash/get";
import Moment from "moment";
import NumberFormat from "react-number-format";
import "./forms.scss";
import { withTrans } from "../../i18n/withTrans";

class Input extends Component {

  render() {
    const exceptThisSymbols = ["e", "E", "+", "-", "."];
    const {
      errors,
      touched,
      objname,
      name,
      currency,
      title,
      leftIcon,
      righticon,
      value,
      placeholder,
      styleForm,
      customClassForm
    } = this.props;

    const error = errors ? (objname && objname?.parent ? (errors[objname?.parent] ? errors[objname?.parent][objname?.child] : errors[objname?.parent]) : errors[name]) : false
    const touch = touched ? (objname && objname?.parent ? (touched[objname?.parent] ? touched[objname?.parent][objname?.child] : touched[objname?.parent]) : touched[name]) : false
    return (
      <div className={`form-group ${customClassForm ?? ''}`} style={styleForm}>
        {title && (
          <label className="form-label">{title}</label>
        )}
        <div className="input-wrapper" style={{ width: "100%" }}>
          {leftIcon && (
            <span className="material-icons-round left-icon-input">
              {leftIcon}
            </span>
          )}
          {currency && (
            <NumberFormat
              className={
                "form-control form-input" +
                this.props?.variant +
                (this.props?.disabled ? "input-disabled " : "") +
                (error && touch
                  ? " is-invalid"
                  : "")
              }
              allowNegative={false}
              value={value != null ? value : ""}
              thousandSeparator={true}
              prefix={"Rp "}
              placeholder={placeholder}
              onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
              onChange={this.props?.onChangeValue}
              onValueChange={(values) => {
                const { value } = values;
                if (this.props.toggleCurrency) {
                  this.props?.toggleCurrency?.setFieldValue(`${this.props.name}`, value)
                }
              }}
              style={{ padding: "25px" }}
              disabled={this.props?.disabled}
            />
          )}

          {!currency && (
            <input
              {...this.props}
              className={
                this.props.customClassInput +
                " form-control form-input" +
                this.props?.variant +
                (this.props?.disabled ? "input-disabled " : "") +
                (error && touch
                  ? " is-invalid"
                  : "")
              }
              value={value != null ? value : ""}
              onChange={this.props?.onChange}
              onKeyUp={this.props?.onKeyUp}
              onKeyDown={this.props?.onKeyDown}
              onWheel={(e) => this.props?.type === "number" ? e.target.blur() : console.log("")}
              disabled={this.props?.disabled}
              maxLength={this.props?.maxLength}
              style={this.props?.style ?? { padding: "25px", borderRadius: 6 }}
              type={this.props?.type}
              min={this.props?.min}
              max={this.props?.max}
              step={this.props?.step}
              data-date={
                this.props?.type === "date" && value
                  ? Moment(value).format("DD/MM/YYYY")
                  : "dd/mm/yyyy"
              }
              placeholder={
                this.props.placeholder ??
                this.props.t("commons.input") + (this.props.title ?? "data")
              }
            />
          )}

          {righticon && (
            <span
              className="material-icons-round right-icon-input"
              style={{
                cursor: this.props.toggleicon ? "pointer" : "",
                top: 15,
                right: 8
              }}
              onClick={this.props.toggleicon}
            >
              {righticon}
            </span>
          )}

          {this.props?.rightLabel && (
            <span
              className="right-icon-input"
              style={{
                cursor: this.props?.toggleicon ? "pointer" : "",
                opacity: "0.8",
              }}
              onClick={this.props?.toggleicon}
            >
              {this.props?.rightLabel}
            </span>
          )}

          {/* {(value === null || value === undefined || value === "") && (
            <ErrorMessage
              name={this.props?.name}
              component="div"
              className="invalid-feedback"
              style={{ display: "block" }}
            />
          )} */}
          
          <div
              className={`invalid-feedback ${errors && touched ? get(errors, name) ? 'd-block' : 'd-none' : ''}`}
          >
              {errors ? get(errors, name) : ''}
          </div>
        </div>
      </div>
    );
  }
}

export default withTrans(Input);
