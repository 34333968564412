import { actionPending, actionSuccess, actionError } from "../../actionTypes";

import { toastSuccess, toastError } from "../../../../components/commons/toast";

import API from "../../API";
import { handleResponse } from "../../HandleRespone";

// ACTION TYPES TO DISPATCH: METHOD_URL_TYPES
export const GET_LOUNGE_PENDING = "GET_LOUNGE_PENDING";
export const GET_LOUNGE_SUCCESS = "GET_LOUNGE_SUCCESS";
export const GET_LOUNGE_ERROR = "GET_LOUNGE_ERROR";
export const POST_LOUNGE_PENDING = "POST_LOUNGE_PENDING";
export const POST_LOUNGE_SUCCESS = "POST_LOUNGE_SUCCESS";
export const POST_LOUNGE_ERROR = "POST_LOUNGE_ERROR";
export const PUT_LOUNGE_PENDING = "PUT_LOUNGE_PENDING";
export const PUT_LOUNGE_SUCCESS = "PUT_LOUNGE_SUCCESS";
export const PUT_LOUNGE_ERROR = "PUT_LOUNGE_ERROR";
export const DELETE_LOUNGE_PENDING = "DELETE_LOUNGE_PENDING";
export const DELETE_LOUNGE_SUCCESS = "DELETE_LOUNGE_SUCCESS";
export const DELETE_LOUNGE_ERROR = "DELETE_LOUNGE_ERROR";

// URL: URL_{URL}
const LOUNGE_URL = `v1/master_data/lounge`;

const lang = localStorage.getItem('joumpa_language')

const get = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(GET_LOUNGE_PENDING));
    let airportIdString = ""
    param?.airport_ids?.forEach(id => {
        airportIdString = airportIdString + "&airport_ids=" + id
    })

    const getLoungeUrl = param.date && param.time
        ? LOUNGE_URL +
            `?length=${param.length}` +
            `&page=${param.page}` +
            `&id=${param.id || ''}` +
            `&airport_id=${param.airport_id || ''}` +
            `&date=${param.date}` +
            `&time=${param.time}` +
            `&route_type=${param.route_type || ''}` +
            `&flight_type=${param.flight_type || ''}` +
            `&search_text=${param.search_text || ''}` +
            airportIdString
        : LOUNGE_URL +
            `?length=${param.length}` +
            `&page=${param.page}` +
            `&id=${param.id || ''}` +
            `&airport_id=${param.airport_id || ''}` +
            `&route_type=${param.route_type || ''}` +
            `&flight_type=${param.flight_type || ''}` +
            `&search_text=${param.search_text || ''}` +
            airportIdString

    API.get(getLoungeUrl)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(GET_LOUNGE_SUCCESS, res));
            if (resolve) {
                const records_total = res.data.total;
                let data = res.data.data.map((item, i) => ({
                    ...item,
                    no: i + 1 + (param?.page - 1) * param?.length,
                }));
                resolve({
                    data: data,
                    page: param?.page - 1,
                    totalCount: records_total,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(GET_LOUNGE_ERROR));
            let not_logout = handleResponse(err?.response);
            if (not_logout) {
                if (lang === 'id') {
                  toastError(err?.response?.data?.message);
                } else if (lang === "en-US" || lang === "en") {
                  toastError(err?.response?.data?.message_eng ?? err?.response?.data?.message);
                }
            }
        });
};

const post = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(POST_LOUNGE_PENDING));
    API.post(LOUNGE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(POST_LOUNGE_SUCCESS, res));
            toastSuccess(lang === 'id' ? "Berhasil Tambah Data Lounge" : "Lounge Data Successfully Added");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(POST_LOUNGE_ERROR, err?.response));
            if (reject) {
              reject(err?.response);
            }
        });
};

const put = (param, resolve, reject, callback) => (dispatch) => {
    dispatch(actionPending(PUT_LOUNGE_PENDING));
    API.put(LOUNGE_URL, param)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(PUT_LOUNGE_SUCCESS, res));
            toastSuccess(lang === 'id' ? "Berhasil Ubah Data Lounge" : "Lounge Data Successfully Changed");
            if (resolve) {
                resolve({
                    data: res.data.data,
                    message: res.data.message,
                    status: res.data.status,
                });
            }
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(PUT_LOUNGE_ERROR, err?.response));
            if (reject) {
              reject(err?.response);
            }
        });
};

const deleted = (param, reject, callback, name) => (dispatch) => {
    dispatch(actionPending(DELETE_LOUNGE_PENDING));
    API.delete(LOUNGE_URL + `?id=${param}`)
        .then((res) => {
            if (res.error) {
                throw res.error;
            }
            dispatch(actionSuccess(DELETE_LOUNGE_SUCCESS, res));
            toastSuccess(lang === 'id' ? `Berhasil Hapus Data Lounge ${name}` : `Lounge ${name} Successfully Removed`);
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            dispatch(actionError(DELETE_LOUNGE_ERROR, err?.response));
            if (reject) {
              reject(err?.response);
            }
        });
};

const lounge = {
    get,
    post,
    put,
    deleted,
};
export default lounge;
